.select_styles {
  width: 100%;
}
.select_styles label {
  font-size: 12px !important;
}
.select_styles > div > div > div > div > div {
  height: 33px !important;
  font-size: 12px;
  font-family: 'SFProText Regular';
}
.select_styles > div > div > div > div > div > span {
  line-height: 31px !important;
  font-family: 'SFProText Regular';
}
.content {
  padding: 50px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.row_button {
  display: flex;
  justify-content: center;
}
.row_cancel_button {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.cancel_button {
  margin-right: 10px;
}
.cancel_button:hover {
  background-color: #4945ff;
  color: white;
}
.submit_button_style {
  background-color: #4945ff;
  color: white;
}
.submit_button_style:hover {
  background-color: white;
  color: #4945ff;
}
.card_content {
  width: 100%;
}
.card_title {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054 !important;
}
.content_vendor_status {
  padding: 20px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.vendor_ststus_SubText {
  font-size: 12px;
  color: #6a6c6e;
  margin-left: 5px;
}
.message_text {
  margin-top: 20px;
}
.ok_button {
  margin-right: 10px;
  background-color: #4945ff;
  color: white;
}
.ok_button:hover {
  background-color: white;
  color: #4945ff;
}
