.user_menu_container {
  background: #FAFAFA;
  border: 1px solid #CFD7DF;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 7px 10px;
  .user_menu_avatar {
    background: #F6F0A7;
    margin-right: 3px;
    color: #DCBE43;
  }
}
