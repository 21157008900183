// @import '~antd/dist/antd.less';
@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';
@import './theme.less';
@import './SFProText/style.css';
@import './variables.less';
@import './custom.less';

body {
  margin: 0;
  font-family: 'SFProText Regular', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
