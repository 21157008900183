.custom_table_container {
  height: 100%;
  .empty_state_text {
    color: #666687;
    margin: 10px 10px;
    margin-bottom: 20px;
  }
  :global(.ant-table-thead) {
    :global(.ant-table-cell) {
      font-family: 'SFProText Regular';
      padding: 10px 30px 10px 10px; // table header //
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 16px;
      background-color: transparent !important;
      // text-align: center;
    }
  }
  :global(.ant-table-thead) {
    :global(.ant-table-cell) {
      :global(.ant-table-column-sorter) {
        padding-left: 0 !important;
        margin-left: 0 !important;
        size: 0px !important;
        float: none !important;
        position: relative !important;
      }
    }
  }
  :global(.ant-table-tbody) {
    :global(.ant-table-cell) {
      font-family: 'SFProText Regular';
      padding: 5px 30px 5px 10px; // table cell //
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      color: #32324d;
      //text-align: center;
    }
  }
  // :global(.ant-table-tbody) {
  //   // :global(.ant-table-cell) {
  //   // //  padding: 0;
  //   // }
  // }
  &.no_data {
    :global(.ant-table-content) {
      height: 100%;
      table {
        height: calc(100% - 0px);
        :global(.ant-table-tbody) {
          height: 100%;
        }
      }
    }
  }
}
.ant-table-column-sorter {
  margin-left: 0 !important;
}

/* .custom_table_container {
  height: 100%;
  .empty_state_text {
    color: #666687;
    margin: 10px 10px;
    margin-bottom: 20px;
  }
  :global(.ant-table-thead) {
    :global(.ant-table-cell) {
      font-family: 'SFProText Regular';
      padding: 10px 30px 10px 20px; // table header //
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 16px;
      background-color: transparent !important;
      // text-align: center;
      flex-direction: row;
      // display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  :global(.ant-table-thead) {
    :global(.ant-table-cell) {
      :global(.ant-table-column-sorter) {
        padding-left: 0 !important;
        margin-left: 0 !important;
        size: 0px !important;
        float: none !important;
        position: relative !important;
      }
    }
  }
  :global(.ant-table-tbody) {
    :global(.ant-table-cell) {
      font-family: 'SFProText Regular';
      padding: 0px 30px 0px 20px !important; // table cell /
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      // line-height: 16px;
      color: #32324d;
      // text-align: center;
      flex-direction: row;
      // display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  :global(.ant-table-tbody) {
    :global(.ant-table-cell) {
      padding: 0;
    }
  }
  &.no_data {
    :global(.ant-table-content) {
      height: 100%;
      table {
        height: calc(100% - 0px);
        :global(.ant-table-tbody) {
          height: 100%;
        }
      }
    }
  }
}
.ant-table-column-sorter {
  margin-left: 0 !important;
}
 */
