@font-face {
  font-family: 'SFProText Regular';
  font-style: normal;
  font-weight: normal;
  src: local('SFProText Regular'),
    url('SFProText Regular.otf') format('opentype');
}

@font-face {
  font-family: 'SFProText Medium';
  font-style: normal;
  font-weight: normal;
  src: local('SFProText Medium'), url('SFProText Medium.otf') format('opentype');
}

@font-face {
  font-family: 'SFProText Bold';
  font-style: normal;
  font-weight: normal;
  src: local('SFProText Bold'), url('SFProText Bold.otf') format('opentype');
}

.sfprotext-regular {
  font-family: 'SFProText Regular' !important;
}

.sfprotext-medium {
  font-family: 'SFProText Medium' !important;
}

.sfprotext-bold {
  font-family: 'SFProText Bold' !important;
}
