.card_container {
  box-shadow: 0px 0px 10px rgba(127, 161, 250, 0.15);
  border-radius: 4px;
  // padding: 1rem 1rem;
  // display: flex;
  //justify-content: space-between;
}
.card_content_style {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  flex-direction: row;

  align-items: flex-start;
  padding: 20px 35px;
  // gap: 15px;
  isolation: isolate;
  background: #ffffff;
  border: 1px solid #f4f4f4;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
}
.card_icon_div {
  margin-right: 6px;
  // margin-top: 1px;
}
.card_icon_style {
  font-size: 20px;
  color: #4059e5;
}
.card_icon_style_img {
  width: 26px !important;
  height: 26px !important;
  color: #4059e5 !important;
}
.card_title_style {
  font-size: 18px;
  color: #667085;
  font-weight: 600;
  line-height: 20px;
}
.card_amount_style {
  margin-top: 4px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
  line-height: 38px;
}
