.title_container {
  display: flex;
  justify-content: space-between;
  // font-family: sfprotext-bold font-22;
  // font-size: 22px;
}
.text_Title {
  color: #4059e5;
}
.dateRange_container > div > div {
  height: 32px !important;
  font-size: 12px;
}
.dateRange_container > div > div > span {
  line-height: 32px !important;
  height: 32px !important;
  font-size: 12px;
}
.dateRange_container > div > div > span > input {
  height: 34px !important;
  font-size: 12px;
}
.card_container {
  box-shadow: 0px 0px 10px rgba(127, 161, 250, 0.15);
  border-radius: 4px;
  padding: 1rem 1rem;
  // display: flex;
  //justify-content: space-between;
}
.chartView_container {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(127, 161, 250, 0.15);
  border-radius: 4px;
  padding: 1rem 2rem;
}
.speedometerView_container {
  box-shadow: 0px 0px 10px rgba(127, 161, 250, 0.15);
  border-radius: 4px;
  // padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  //background: #ffffff;
}
.speedometer_container {
  background: #ffffff;
  padding: 20px;
  border-radius: 4px;
}
.speedometer_VerticalBar_container {
  background: #ffffff;
  padding: 20px;
  border-radius: 4px;
}
// .doughnut_container {
//   box-shadow: 0px 0px 10px rgba(127, 161, 250, 0.15);
//   border-radius: 4px;
//   padding: 1rem 2rem;
//   display: flex;
//   justify-content: space-between;
//   background: #ffffff;
// }
.tableView_container {
  box-shadow: 0px 0px 10px rgba(127, 161, 250, 0.15);
  border-radius: 4px;
  // padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  //background: #ffffff;
}
.table_container {
  background: #ffffff;
  padding: 20px 20px 0px 20px;
  border-radius: 4px;
}
.horiz_container {
  background: #ffffff;
  padding: 20px;
  border-radius: 4px;
}
