/* .right_outer_container {
  height: 100%;
  width: 60%;
  margin: auto;
  .right_image {
    width: 50%;
    margin: 0 auto;
  }
}
.form_width {
  width: 100% !important;
}
.left_outer_container {
  background: white;
  height: 100%;
  padding: 60px 50px;
}
.secondary_button {
  color: white !important;
  background: #1cb697 !important;
  border-radius: 4px;
  border: 1px solid #1cb697 !important ;
}

.image_holder {
  height: 100vh;
  position: fixed;
}

.rect_image {
  right: 0px;
  bottom: 0;
  position: fixed;
  width: 35%;
}

.overlay_image {
  position: fixed;
  right: 0px;
  bottom: 160px;
  width: 30%;
}

.main_header {
  font-weight: 800;
  font-size: 60px;
  line-height: 65px;

  letter-spacing: -0.02em;

  color: #1c4e9d;
}

.row_container {
  width: 250px;
  height: 250px;
  background: #f0f0ff;
  border-radius: 5px;
  padding: 30px;
  margin-right: 10px;
}
@media only screen and (max-width: 1150px) {
  .row_container {
    width: 170px;
    height: 220px;
    font-size: 11px;
  }
  .rect_image {
    width: 30%;
  }
  .main_header {
    width: 98%;
    font-size: 41px;
  }
}
.tata_logo {
  right: 0px;
  top: 0;
  z-index: 1;
  position: fixed;
}

.left_screen {
  z-index: 8;
  display: inline-block;
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: rgb(255, 255, 255);
}
@media (min-width: 768px) {
  .left_screen {
    float: left;

    left: 0;
    top: 0;
    height: 100%;
    width: 60%;
  }
}

.right_screen {
  display: inline-block;
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: rgb(255, 255, 255);
}
@media (min-width: 768px) {
  .right_screen {
    float: right;

    right: 0;
    top: 0;
    height: 100%;
    width: 40%;
  }
}

@media (max-width: 768px) {
  .right_screen {
    display: none;
    float: right;

    right: 0;
    top: 0;
    height: 100%;
    width: 35%;
  }
}

@media only screen and (min-width: 2260px) {
  .right_logo {
    height: 100px;
  }
  .left_logo {
    height: 140px;
    width: 400px;
  }
  .secondary_button {
    width: 400px;
    font-size: 25px;
    height: 85px;
  }
  .row_container {
    width: 450px;
    height: 500px;
    font-size: 32px;
    margin-right: 25px;
    margin-top: 5rem !important;
    margin-bottom: 4rem !important;
    img {
      height: 100px;
      width: 100px;
    }
  }
  .rect_image {
    width: 30%;
  }
  .main_header {
    width: 98%;
    font-size: 120px;
    line-height: initial;
  }
}
.tata_logo {
  right: 0px;
  top: 0;
  z-index: 1;
  position: fixed;
}

@media only screen and (max-width: 1300px) {
  .row_container {
    width: 200px;
    height: 220px;
    font-size: 11px;
  }
}

@media only screen and (max-width: 1024px) {
  .row_container {
    width: 175px;
    height: 220px;
    font-size: 11px;
  }
}

@media only screen and (max-width: 1560px) {
  .main_header {
    font-size: 53px;
  }
}

@media only screen and (max-width: 1348px) {
  .main_header {
    font-size: 50px;
  }
} */
/* ------------------------New A2P Screen---------------------------------------------- */
@media only screen and (max-width: 1348px) and (min-width: 1194px) {
  .full_container {
    position: relative;
    width: auto !important;
    max-width: 100%;
    // min-height: 100vh;
    height: 100%;
    background-color: rgb(255, 255, 255);
  }
  .money_container {
    position: absolute;
    margin-bottom: 4rem !important;
    .money_container_img {
      width: 165%;
      height: 450px;
      margin-top: 2rem !important;
      margin-left: 0px;
    }
  }
  .left_logo {
    height: 60px;
    width: 150px;
    margin-top: 1rem;
    margin-left: 2rem;
  }
  .drinking_bro_container {
    position: absolute;
    width: 100%;
    height: 750px;
    margin-right: 0;
    margin-left: 0;
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .drinking_bro_container_img {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 750px;
  }

  .overlay_image {
    position: absolute;
    left: 44%;
    //position: fixed;
    //left: 63%;
    // top: 12.5rem;
    //right:24%;
    bottom: 160px;
    width: 37%;
    top: 13rem;
  }
  .text_container_img {
    height: 150%;
    width: 150%;
    margin-bottom: 1rem;
  }
  .check_item_img {
    height: 150%;
    width: 150%;
    margin-bottom: 1rem;
  }
  .login_button {
    border-radius: 8px;
    padding: 10px, 18px, 10px, 18px;
    background-color: #ffffff;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid #ffffff !important ;
  }
  .login_button:hover {
    color: #ffffff;
    background-color: rgb(73, 71, 74);
  }
  .text_container_one {
    height: 150%;
    width: 150%;
    margin-bottom: 4px;
    color: #ffffff;
    //font-family: 'Cerebri Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  }
  .text_container_two {
    height: 150%;
    width: 150%;
    margin-bottom: 16px;
    color: #ffffff;
    //font-family: 'Cerebri Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }
  .check_item_container {
    margin-bottom: 16px;
    color: #ffffff;
    //font-family: 'Cerebri Sans';
    font-style: normal;
    font-weight: 500;

    line-height: 18px;
  }
  .check_item_text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 1rem;
    margin-left: 5px;
  }
  .check_item_img1 {
    height: 5%;
    width: 5%;
    // margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 1198px) and (min-width: 770px) {
  .money_container {
    position: absolute;
    margin-bottom: 4rem !important;
    .money_container_img {
      width: 145%;
      height: 450px;
      margin-top: 2rem !important;
      margin-left: 0px;
    }
  }
  .left_logo {
    height: 60px;
    width: 150px;
    margin-top: 1rem;
    margin-left: 2rem;
  }
  .drinking_bro_container {
    position: absolute;
    width: 100%;
    height: 750px;
    margin-right: 0;
    margin-left: 0;
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .drinking_bro_container_img {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 750px;
  }

  .overlay_image {
    position: absolute;
    left: 44%;
    //position: fixed;
    //left: 63%;
    // top: 12.5rem;
    //right:24%;
    bottom: 160px;
    width: 37%;
    top: 13rem;
  }
  .text_container_img {
    height: 150%;
    width: 150%;
    margin-bottom: 1rem;
  }
  .check_item_img {
    height: 150%;
    width: 150%;
    margin-bottom: 1rem;
  }
  .login_button {
    border-radius: 8px;
    padding: 10px, 18px, 10px, 18px;
    background-color: #ffffff;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid #ffffff !important ;
  }
  .login_button:hover {
    color: #ffffff;
    background-color: rgb(73, 71, 74);
  }
  .text_container_one {
    height: 150%;
    width: 150%;
    margin-bottom: 4px;
    color: #ffffff;
    //font-family: 'Cerebri Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  }
  .text_container_two {
    height: 150%;
    width: 150%;
    margin-bottom: 16px;
    color: #ffffff;
    //font-family: 'Cerebri Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }
  .check_item_container {
    margin-bottom: 16px;
    color: #ffffff;
    //font-family: 'Cerebri Sans';
    font-style: normal;
    font-weight: 500;

    line-height: 18px;
  }
  .check_item_text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 1rem;
    margin-left: 5px;
  }
  .check_item_img1 {
    height: 5%;
    width: 5%;
    // margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 1560px) and (min-width: 1348px) {
  .money_container {
    position: absolute;
    margin-bottom: 4rem !important;
    .money_container_img {
      width: 195%;
      height: 450px;
      margin-top: 2rem !important;
      margin-left: 0px;
    }
  }
  .left_logo {
    height: 60px;
    width: 150px;
    margin-top: 1rem;
    margin-left: 2rem;
  }
  .drinking_bro_container {
    position: absolute;
    width: 100%;
    height: 750px;
    margin-right: 0;
    margin-left: 0;
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .drinking_bro_container_img {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 750px;
  }
  .overlay_image {
    position: absolute;
    left: 44%;
    //position: fixed;
    //left: 63%;
    // top: 12.5rem;
    //right:24%;
    bottom: 160px;
    width: 37%;
    top: 13rem;
  }
  .text_container_img {
    height: 150%;
    width: 150%;
    margin-bottom: 1rem;
  }
  .check_item_img {
    height: 150%;
    width: 150%;
    margin-bottom: 1rem;
  }
  .login_button {
    border-radius: 8px;
    padding: 10px, 18px, 10px, 18px;
    background-color: #ffffff;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid #ffffff !important ;
  }
  .login_button:hover {
    color: #ffffff;
    background-color: rgb(73, 71, 74);
  }
  .text_container_one {
    height: 150%;
    width: 150%;
    margin-bottom: 4px;
    color: #ffffff;
    //font-family: 'Cerebri Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  }
  .text_container_two {
    height: 150%;
    width: 150%;
    margin-bottom: 16px;
    color: #ffffff;
    //font-family: 'Cerebri Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }
  .check_item_container {
    margin-bottom: 16px;
    color: #ffffff;
    //font-family: 'Cerebri Sans';
    font-style: normal;
    font-weight: 500;

    line-height: 18px;
  }
  .check_item_text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 1rem;
    margin-left: 5px;
  }
  .check_item_img1 {
    height: 5%;
    width: 5%;
    // margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 2260px) and (min-width: 1560px) {
  .money_container {
    position: absolute;
    margin-bottom: 4rem !important;
    .money_container_img {
      width: 195%;
      height: 450px;
      margin-top: 2rem !important;
      margin-left: 0px;
    }
  }
  .left_logo {
    height: 60px;
    width: 150px;
    margin-top: 1rem;
    margin-left: 2rem;
  }
  .drinking_bro_container {
    position: absolute;
    width: 100%;
    height: 750px;
    margin-right: 0;
    margin-left: 0;
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .drinking_bro_container_img {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 750px;
  }
  .overlay_image {
    position: absolute;
    left: 44%;
    //position: fixed;
    //left: 63%;
    // top: 12.5rem;
    //right:24%;
    bottom: 160px;
    width: 37%;
    top: 13rem;
  }
  .text_container_img {
    height: 150%;
    width: 150%;
    margin-bottom: 1rem;
  }
  .check_item_img {
    height: 150%;
    width: 150%;
    margin-bottom: 1rem;
  }
  .login_button {
    border-radius: 8px;
    padding: 10px, 18px, 10px, 18px;
    background-color: #ffffff;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid #ffffff !important ;
  }
  .login_button:hover {
    color: #ffffff;
    background-color: rgb(73, 71, 74);
  }
  .text_container_one {
    height: 150%;
    width: 150%;
    margin-bottom: 4px;
    color: #ffffff;
    //font-family: 'Cerebri Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  }
  .text_container_two {
    height: 150%;
    width: 150%;
    margin-bottom: 16px;
    color: #ffffff;
    //font-family: 'Cerebri Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }
  .check_item_container {
    margin-bottom: 16px;
    color: #ffffff;
    //font-family: 'Cerebri Sans';
    font-style: normal;
    font-weight: 500;

    line-height: 18px;
  }
  .check_item_text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 1rem;
    margin-left: 5px;
  }
  .check_item_img1 {
    height: 5%;
    width: 5%;
    // margin-bottom: 1rem;
  }
}
//deployed on 10-01-2023
@media only screen and (max-width: 768px) and (min-width: 320px) {
  .full_container {
    position: relative;
    width: auto !important;
    max-width: 100%;
    // min-height: 100vh;
    height: auto !important;
    // height: calc(100% - 10rem);
    background-color: rgb(255, 255, 255);

    .money_container {
      position: absolute;
      margin-bottom: 14rem !important;
      width: 90%;
      height: 600px;
      margin-top: 0rem;
      .money_container_img {
        width: 340%;
        height: 340px;
        margin-left: 1rem;
        // margin-top: -7rem;
        margin-right: 0rem;
      }
    }
    .left_logo {
      height: 80px;
      width: 120px;
      margin-top: 1rem;
      margin-left: 2rem;
      margin-bottom: 5rem;
    }
    .drinking_bro_container {
      // position: absolute;
      width: 100%;
      height: 0%;
      top: 0rem;
    }
    .drinking_bro_container_img {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 0px;
      display: none;
    }

    .overlay_image {
      position: absolute;
      // left: 56%;
      //position: fixed;
      //left: 63%;
      // top: 12.5rem;
      //right:24%;
      bottom: 160px;
      // width: 37%;
      top: 8rem;
      margin-left: 0rem;
      width: 100%;
    }
    .text_container_img {
      height: 100%;
      width: 100%;
      margin-bottom: 1rem;
      display: none;
    }
    .check_item_img {
      height: 100%;
      width: 100%;
      margin-bottom: 1rem;
      display: none;
    }
    .login_button {
      width: 80%;
      height: 80%;
      border-radius: 8px;
      padding: 10px, 10px, 10px, 10px;
      padding-left: 10px;
      background-color: #006096;
      color: #ffffff;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border: 1px solid #ffffff !important ;
      font-size: 16px;
      top: 28rem;
      margin-left: -2rem;
    }
    .login_button:hover {
      color: #006096;
      background-color: #ffffff;
    }
    .text_container_one {
      height: 100%;
      width: 100%;
      // margin-bottom: 4px;
      color: #006096;
      //font-family: 'Cerebri Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 18px;
      margin-left: -5rem;
    }
  }
  .text_container_two {
    height: 100%;
    width: 100%;
    margin-bottom: 16px;
    color: #ffffff;
    //font-family: 'Cerebri Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    display: none;
  }
  .check_item_container {
    margin-bottom: 16px;
    color: #ffffff;
    //font-family: 'Cerebri Sans';
    font-style: normal;
    font-weight: 500;

    line-height: 18px;
    display: none;
  }
  .check_item_text {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    color: #ffffff;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 1rem;
    margin-left: 5px;
    display: none;
  }
  .check_item_img1 {
    height: 3%;
    width: 3%;
    // margin-bottom: 1rem;
    display: none;
  }
}

/* @media only screen and (max-width: 768px) and (min-width: 320px) {
  .full_container {
    position: relative;
    width: auto !important;
    max-width: 100%;
    min-height: 100vh;
    height: 100%;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    .money_container {
      position: absolute;
      margin-bottom: 14rem !important;
      width: 90%;
      height: 600px;
      margin-top: 7rem;
      .money_container_img {
        width: 340%;
        height: 340px;
        margin-left: 1.2rem;
        // margin-right: 0rem;
      }
    }
    .left_logo {
      height: 100px;
      width: 140px;
      margin-top: -8rem;
      margin-left: 2rem;
    }
    .drinking_bro_container {
      // position: absolute;
      width: 100%;
      height: 50%; //-------------
      top: 20rem;
    }
    .drinking_bro_container_img {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 700px;
      display: none;
    }

    .overlay_image {
      position: absolute;
      left: 0%;
      //position: fixed;
      //left: 63%;
      // top: 12.5rem;
      //right:24%;
      bottom: 160px;
      // width: 37%;
      top: 5rem;
      margin-left: 0rem;
      width: 100%;
      //   display: flex;
      // justify-content: center;
      // justify-items: center;
    }
    .text_container_img {
      height: 100%;
      width: 100%;
      margin-bottom: 1rem;
      display: none;
    }
    .check_item_img {
      height: 100%;
      width: 100%;
      margin-bottom: 1rem;
      display: none;
    }
    .login_button_div {
      display: flex;
      justify-content: center;
      justify-items: center;
    }
    .login_button {
      width: 80%;
      height: 80%;
      border-radius: 8px;
      padding: 10px, 10px, 10px, 10px;
      padding-left: 10px;
      background-color: #006096;
      color: #ffffff;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border: 1px solid #ffffff !important ;
      font-size: 16px;
      line-height: 34px;
      top: 29rem;
      margin-left: -7rem;
    }
    .login_button:hover {
      color: #006096;
      background-color: #ffffff;
    }
    .text_container_one {
      height: 100%;
      width: 100%;
      // margin-bottom: 4px;
      color: #006096;
      //font-family: 'Cerebri Sans';
      font-style: normal;
      // font-weight: 700;
      // font-size: 22px;
      // line-height: 18px;
      margin-left: -5rem;
      margin-top: 6rem;
    }
    .text_container_one_span {
      font-weight: 900;
      font-size: 21px;
      line-height: 18px;
    }
    .text_container_two {
      height: 100%;
      width: 100%;
      margin-bottom: 16px;
      color: #ffffff;
      //font-family: 'Cerebri Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 18px;
      display: none;
    }
    .check_item_container {
      margin-bottom: 16px;
      color: #ffffff;
      //font-family: 'Cerebri Sans';
      font-style: normal;
      font-weight: 500;

      line-height: 18px;
      display: none;
    }
    .check_item_text {
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 18px;
      color: #ffffff;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      margin-bottom: 1rem;
      margin-left: 5px;
      display: none;
    }
    .check_item_img1 {
      height: 3%;
      width: 3%;
      // margin-bottom: 1rem;
      display: none;
    }
  }
} */
