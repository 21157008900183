.custom_layout_container {
  min-height: 100vh;
  .sider_container {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background: #4059e5;
    box-shadow: 0px 0px 5px rgba(39, 49, 58, 0.1);
    :global(.ant-layout-sider-children) {
      :global(.ant-menu.ant-menu-inline-collapsed) {
        margin: 0 12px;
        background: #4059e5;
      }
      :global(.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)) {
        :global(.ant-menu-item) {
          margin: 0;
          background: #4059e5;
          border-radius: 4px;
          margin-top: 14px;
          //  :global(.anticon) {
          //   //color: #abace2;
          //   color: #fff;
          // }
        }
        :global(.ant-menu-item-selected) {
          background: #ffffff;
          :global(.anticon) {
            color: #4059e5;
          }
        }
      }
    }
  }
  .sider_logo {
    height: 32px;
    margin: 16px;
  }

  .site_layout {
    margin-left: 64px;
    .site_layout_header {
      position: fixed;
      z-index: 1;
      width: calc(100% - 64px);
      background: #fff;
      padding: 0 10px 0 20px;
    }
    .site_layout_content {
      margin: 20px;
      margin-top: 80px;
    }
  }
}

.sidebar_icon {
  color: #fff !important;
}
.sidebar_icon:hover {
  color: #4059e5 !important;
  background: #fff !important;
}
.sidebar_icon:active {
  color: #4059e5 !important;
  background: #fff !important;
}

/* .sider_container {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background: #1e206a;
  box-shadow: 0px 0px 5px rgba(39, 49, 58, 0.1);
  :global(.ant-layout-sider-children) {
    :global(.ant-menu.ant-menu-inline-collapsed) {
      margin: 0 12px;
      background: #1e206a;
    }
    :global(.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)) {
      :global(.ant-menu-item) {
        margin: 0;
        background: #1e206a;
        border-radius: 4px;
        margin-top: 14px;
        :global(.anticon) {
          color: #abace2;
        }
      }
      :global(.ant-menu-item-selected) {
        background: #ffffff;
        :global(.anticon) {
          color: #1e206a;
        }
      }
    }
  }
} */
