.invoices_container {
  height: calc(100% - 100px);
  .invoices_table_container {
    margin-top: 10px;
    height: 100%;
    background: #ffffff;
    padding: 20px;
  }
}

input:placeholder-shown {
  font-size: 11px !important;
}
.allocation_btn {
  padding: 0px 0px !important;
  height: 20px !important;
}
.status_tag {
  //margin-left: 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.custTag {
  background: #ebeff3;
  border: 1px solid #dcdce4;
  border-radius: 10px;
  padding: 10px 14px;
  margin-right: 10px;
}
.liabilityNotAvailable_text {
  color: #000000;
  // color: rgba(0, 0, 0, 0.8);
}
.liabilityNotAvailable_text:hover {
  color: #4945ff;
}
