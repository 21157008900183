.sub_header_tabs_container {
  margin-bottom: -24px;
  .sub_header_tabs_space {
    .sub_header_tab {
      cursor: pointer;
      .horizontal_line {
        height: 2px;
        background-color: transparent;
        margin-top: 12px;
      }

      &.selected {
        border-bottom-width: 2px;
        border-bottom-color: #4945ff;

        .sub_header_tab_text {
          color: #4945ff;
        }

        .horizontal_line {
          background-color: #4945ff;
        }
      }
    }
  }
}
.tab_style_fontSize {
  color: #8e8ea9 !important;
  font-size: 13px;
}
@media only screen and (max-width: 1164px) {
  .tab_style_fontSize {
    color: #8e8ea9 !important;
    font-size: 11px;
  }
}
@media only screen and (max-width: 1024px) {
  .tab_style_fontSize {
    color: #8e8ea9 !important;
    font-size: 10px;
  }
}
