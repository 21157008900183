.date_styles label {
  font-size: 13px !important;
}
.date_styles > div > div > div > div {
  height: 30px !important;
  width: 100% !important;
}
/* .date_styles > div > div > div > div > div > input {
  font-size: 12px !important;
} */
.datePicker_styles > div > input {
  font-size: 12px !important;
  height: 24px !important;
  width: 100% !important;
}
//--------------------------------------------
.content_holder {
  margin-right: 40px;
  :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    border-radius: 8px;
    svg {
      color: black;
    }
  }
}
.home_icon {
  margin-top: -3px;
}
.sub_title {
  color: #667085;
}
.export_button {
  width: 167px;
  height: 40px;
}

.content {
  padding: 50px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
