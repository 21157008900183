.secondary_btn {
  background: #f0f0ff;
  border: 1px solid #d9d8ff;
  color: #4945ff;
}
/* new added */
.btn_padding {
  padding: 8px !important;
  font-size: 11px;
}
@media only screen and (max-width: 1164px) {
  .btn_padding {
    padding: 6px !important;
    font-size: 9px;
  }
}
@media only screen and (max-width: 1024px) {
  .btn_padding {
    padding: 6px !important;
    font-size: 8.5px;
  }
}
// @media only screen and (max-width: 1423px) {
//   .btn_padding {
//     // padding: 9px !important;
//     font-size: 13px;
//   }
// }
// @media only screen and (max-width: 1300px) {
//   .btn_padding {
//     // padding: 9px !important;
//     font-size: 13px;
//   }
// }
