.summary_invoice_item_container {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(127, 161, 250, 0.15);
  border-radius: 10px;

  .summary_invoice_item_vendor_details_container {
    background: #f0f0ff;
    border-radius: 8px 0px;
    padding: 6px 16px;
  }

  .summary_invoice_item_req_btn {
    background: #f0f0ff;
    border: 1px solid #dcdce4;
    border-radius: 4px;
    height: 24px;
    line-height: 0;
    padding: 4px 16px;
    color: #32324d;
    font-size: 12px;
    font-family: 'SFProText Medium' !important;
    position: absolute;
    top: 12px;
    right: 15px;
  }

  .summary_invoice_item_liabilities_container {
    padding: 15px 0px 15px 8px; /* card padding */
    font-size: 11px; /* // Liabilities font size */
    .summary_invoice_item_submit_btn {
      font-size: 12px; /* // Invoice button font size */
      font-family: 'SFProText Regular' !important;
      padding-top: 0 !important;
      margin: 0 !important;
    }
  }
}
