.custom_table_container {
  height: 100%;
  .empty_state_text {
    color: #666687;
    margin: 10px 10px;
    margin-bottom: 20px;
  }
  :global(.ant-table-thead) {
    :global(.ant-table-cell) {
      font-family: 'SFProText Regular';
      padding: 8px 30px 8px 10px; //10px 4px; // padding:
      font-style: normal; /* table header */
      font-weight: 700;
      font-size: 11px;
      line-height: 16px;
      background-color: transparent !important;
      // text-align: center;
    }
  }
  :global(.ant-table-thead) {
    :global(.ant-table-cell) {
      :global(.ant-table-column-sorter) {
        padding-left: 0 !important;
        margin-left: 0 !important;
        //color: red !important;
        size: 0px !important;
        float: none !important;
        position: relative !important;
      }
    }
  }
  :global(.ant-table-tbody) {
    :global(.ant-table-cell) {
      font-family: 'SFProText Regular';
      padding: 8px 30px 8px 10px !important; // padding: 0px 0px  0px 10px !important; /* table cell */
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      color: #32324d;
      // text-align: center;
    }
  }
  :global(.ant-table-tbody) {
    :global(.ant-table-cell) {
      padding: 0;
    }
  }
  &.no_data {
    :global(.ant-table-content) {
      height: 100%;
      table {
        height: calc(100% - 0px);
        :global(.ant-table-tbody) {
          height: 100%;
        }
      }
    }
  }
}
.ant-table-column-sorter {
  margin-left: 0 !important;
}
/* .custom_table_container {
  height: 100%;
  .empty_state_text {
    color: #666687;
    margin: 20px 0 10px;
  }
  :global(.ant-table-thead) {
    :global(.ant-table-cell) {
      font-family: 'SFProText Bold';
      font-size: 11.5px; //------------
      color: red; //--------
    }
  }
  :global(.ant-table-wrapper) {
    height: 100%;

    :global(.ant-spin-nested-loading) {
      height: 100%;

      :global(.ant-spin-container) {
        height: 100%;
        display: flex;
        flex-flow: column nowrap;

        :global(.ant-table) {
          flex: auto;
          overflow: hidden;

          :global(.ant-table-container) {
            height: 100%;
            display: flex;
            flex-flow: column nowrap;

            :global(.ant-table-thead) {
              flex: none;
            }

            :global(.ant-table-tbody) {
              flex: auto;
              overflow: scroll;
              :global(.ant-table-placeholder) {
                :global(.ant-table-cell) {
                  border-bottom: none;
                }
              }
            }
          }
        }

        :global(.ant-table-pagination) {
          flex: none;
        }
      }
    }
  }
  &.no_data {
    :global(.ant-table-content) {
      height: 100%;
      table {
        height: calc(100% - 55px);
        :global(.ant-table-tbody) {
          height: 100%;
        }
      }
    }
  }
}
 */
