.date_styles label {
  font-size: 13px !important;
}
.date_styles > div > div > div > div {
  height: 30px !important;
  width: 100% !important;
}
/* .date_styles > div > div > div > div > div > input {
  font-size: 12px !important;
} */
.datePicker_styles > div > input {
  font-size: 12px !important;
  height: 24px !important;
  width: 100% !important;
}
.content {
  padding: 50px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
