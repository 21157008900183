.req_alloc_modal_vendor_details {
  background: #f0f0ff;
  border-radius: 5px;
  padding: 6.5px 16px;
  font-size: 14px;
  border: 1px solid #bcbcf9;
}

.req_alloc_modal_requested_amount_details {
  background: #ebeff3;
  border-radius: 5px;
  padding: 4px 20px;
  border: 1px solid #afd0f4;
}

.req_alloc_modal_invoices_total_amount_details {
  background: #fdf4dc;
  border: 1px solid #f2dcaa;
  border-radius: 5px;
  padding: 4px 20px;
}
.ant_select_size
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 35px !important;
}
