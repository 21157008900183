.invoices_container {
  height: calc(100% - 100px);
  .invoices_table_container {
    margin-top: 10px;
    height: 100%;
    background: #ffffff;
    padding: 20px;
  }
}

input:placeholder-shown {
  font-size: 12px !important;
}
.allocation_btn {
  padding: 0px 0px !important;
  height: 20px !important;
}
.status_tag {
  //margin-left: 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
