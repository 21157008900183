.switcher_container {
  background: #EBEFF3;
  border: 1px solid #DCDCE4;
  border-radius: 10px;
  padding: 10px 14px;
  :global(.ant-typography) {
    color: #000000;
  }
  :global(.ant-switch) {
    background: #4945FF;
  }
}