.req_alloc_modal_vendor_details {
  background: #f0f0ff;
  border-radius: 5px;
  padding: 6.5px 16px;
  font-size: 14px;
  border: 1px solid #bcbcf9;
}

.req_alloc_modal_requested_amount_details {
  background: #ebeff3;
  border-radius: 5px;
  padding: 4px 20px;
  border: 1px solid #afd0f4;
}

.req_alloc_modal_invoices_total_amount_details {
  background: #fdf4dc;
  border: 1px solid #f2dcaa;
  border-radius: 5px;
  padding: 4px 20px;
}

//change font-size of filter tree dropdown in table
:global(.ant-tree) {
  font-size: 11px !important;
}
:global(.ant-dropdown-menu-item){
  font-size: 11px !important;
}
// .ant-dropdown-menu-submenu-title
// .ant-table-filter-dropdown {
//   font-size: 11px !important;
//   .ant-table-filter-dropdown-tree {
//     font-size: 11px !important;
//     .ant-table-filter-dropdown {
//       font-size: 11px !important;
//       .ant-dropdown-menu {
//         font-size: 11px !important;
//         .ant-tree-list {
//           font-size: 11px !important;
//           .ant-tree-list-holder {
//             font-size: 11px !important;
//             .ant-tree {
//               font-size: 11px !important;
//             }
//           }
//         }
//       }
//     }
//   }
// }
// :global(.ant-tree-list) {
//   font-size: 11px !important;
// }
// :global(.ant-dropdown-menu) {
//   font-size: 11px !important;
// }
// :global(.ant-table-filter-dropdown) {
//   font-size: 9px !important;
// }
// :global(.ant-table-filter-dropdown-tree) {
//   font-size: 9px !important;
// }
// :global(.ant-table-filter-dropdown) {
//   font-size: 9px !important;
// }
// :global(.ant-tree-list-holder) {
//   font-size: 11px !important;
// }
